import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { SwiperModule } from 'swiper/angular';

import { MaterialModule } from './material-design/material.module';
import { SidebarComponent } from './components/sidenav/sidenav.component';

import { TableDataComponent } from './components/table-data/table-data.component';
import { SearchComponent } from './components/search/search.component';
import { GraphicComponent } from './components/graphic/graphic.component';
import { DataPropertyGetterPipe } from './pipe/data-property-getter.pipe';
import { HeadComponentComponent } from './components/head-component/head-component.component';
import { LabelRecentComponent } from './components/label-recent/label-recent.component';
import { OptionsGraphicComponent } from './components/dashboard/options-graphic/options-graphic.component';
import { HeadDetailComponent } from './components/detail/head-detail/head-detail.component';
import { MatToolbarComponent } from './components/dashboard/mt-select-date/mt-select-date.component';
import { ButtonViewallComponent } from './components/dashboard/button-viewall/button-viewall.component';
import { LabelPaymentSlopeComponent } from './components/label-payment-slope/label-payment-slope.component';
import { TranslateModule } from '@ngx-translate/core';
import { TabsAutoComponent } from './components/tabs-auto/tabs-auto.component';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { PaginationInternationalization } from './components/table-data/pagination.internationalization';
import { SlideSwpiperComponent } from './components/slide-swpiper/slide-swpiper.component';
import { FactoryDialogComponent } from './components/factory-dialog/factory-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormatDateCustomPipe } from './pipe/format-date-custom.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';

@NgModule({
  declarations: [
    SidebarComponent,
    DataPropertyGetterPipe,
    TableDataComponent,
    SearchComponent,
    GraphicComponent,
    HeadComponentComponent,
    LabelRecentComponent,
    OptionsGraphicComponent,
    HeadDetailComponent,
    MatToolbarComponent,
    ButtonViewallComponent,
    LabelPaymentSlopeComponent,
    TabsAutoComponent,
    SlideSwpiperComponent,
    FactoryDialogComponent,
  ],
  exports: [
    SidebarComponent,
    DataPropertyGetterPipe,
    TableDataComponent,
    SearchComponent,
    GraphicComponent,
    HeadComponentComponent,
    LabelRecentComponent,
    OptionsGraphicComponent,
    HeadDetailComponent,
    MatToolbarComponent,
    ButtonViewallComponent,
    LabelPaymentSlopeComponent,
    TranslateModule,
    TabsAutoComponent,
    SlideSwpiperComponent,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FormatDateCustomPipe,
    NgxMaskModule,
    NgxCurrencyModule,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NgChartsModule,
    TranslateModule.forChild(),
    NgxMaskModule.forRoot(),
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    FormatDateCustomPipe,
    NgxCurrencyModule,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: PaginationInternationalization }],
})
export class SharedModule {}

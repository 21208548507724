import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  readonly BASE_URL: string = `${environment.api_url}`;
  readonly KEY_TOKEN: string = `${environment.key_token}`;
  readonly NAMEHEADER_TOKEN: string = `${environment.header_api_key}`;

  constructor(private http: HttpClient) {}

  getOffers() {
    return this.http.get(`${this.BASE_URL}/rooms/getNewArrayOffers`);
  }

  getOfferDetail(id_offer?: string) {
    return this.http.get(`${this.BASE_URL}/rooms/getDetailOffer/${id_offer}`);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ic-options-graphic',
  templateUrl: './options-graphic.component.html',
  styleUrls: ['./options-graphic.component.scss'],
})
export class OptionsGraphicComponent {
  @Input() options: any;
  @Output() eventChangeDataOptions: EventEmitter<any> = new EventEmitter<any>();

  indexBtn: number = 1;

  constructor() {}

  emitChangeData(selected: string, index: number) {
    this.indexBtn = index;
    this.eventChangeDataOptions.emit({ selected, index });
  }
}

import { GraphService } from '@core/services/graph.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { SubSink } from 'subsink';

import { ProfileType } from '@core/models/profile';
import { environment } from 'src/environments/environment';
import { RolesService } from '@core/services/roles.service';
import { MenuItem } from '@core/models/roles.model';

@Component({
  selector: 'ic-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  environment = environment.production;

  public dataProfile: ProfileType;
  public href: string = '';

  private subs = new SubSink();
  menu: MenuItem[] = [];

  constructor(private _graphService: GraphService, private _rolesService: RolesService) {}

  ngOnInit(): void {
    this.subs.add(
      this._graphService.profile$.subscribe((profile: ProfileType) => {
        this.dataProfile = profile;
      }),
      this._rolesService.menu$.subscribe((menu: MenuItem[]) => {
        this.menu = menu;
      })
    );
  }

  eventActive = function () {
    this.href = this.router.url;
  };

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ic-head-component',
  templateUrl: './head-component.component.html',
  styleUrls: ['./head-component.component.scss'],
})
export class HeadComponentComponent {
  @Input() title;

  constructor() {}
}

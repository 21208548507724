import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '@core/services/loader.service';

import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'ic-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent implements OnInit {
  loading: boolean = true;

  options: AnimationOptions = {
    path: '/assets/Loader_Cars.json',
  };

  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '500px',
    margin: '0 auto',
  };

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }
}

import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConceptosService {
  readonly BASE_URL: string = `${environment.api_url}`;

  constructor(private http: HttpClient) {}

  getPayReferencesProcces(pageSize: number, criteria: string) {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('field', 'creation_date');
    httpParam = httpParam.append('pageSize', pageSize);
    if (criteria !== '') {
      httpParam = httpParam.append('flag', 'criteria');
      httpParam = httpParam.append('criteria', criteria);
    }
    // httpParam = httpParam.append('button', button);
    // httpParam = httpParam.append('field_value', field_value ? field_value : 'date_string');

    return this.http.get(`${this.BASE_URL}/user/rooms/getPaymentsByPage`, {
      params: httpParam,
    });
  }

  approveReferencia(idPago: string, type: string) {
    return this.http.post(`${this.BASE_URL}/user/approve/referencia`, JSON.stringify({ id: idPago, tipo_pago: type }));
  }

  postConcepto(bodyPost: any, idPayment: string) {
    return this.http.post(`${this.BASE_URL}/user/payments/concepto/${idPayment}`, JSON.stringify(bodyPost));
  }

  putConcepto(bodyPut: any, idPayment: string, id_concepto: string) {
    let httpParam = new HttpParams().append('id_concepto', id_concepto);

    return this.http.put(`${this.BASE_URL}/user/payments/concepto/${idPayment}`, JSON.stringify(bodyPut), {
      params: httpParam,
    });
  }

  deleteConcepto(idPayment: string, id_concepto: string) {
    let httpParam = new HttpParams().append('id_concepto', id_concepto);

    return this.http.delete(`${this.BASE_URL}/user/payments/concepto/${idPayment}`, {
      params: httpParam,
    });
  }

  getConceptosFromPayment(id_payment) {
    return this.http.get(`${this.BASE_URL}/payments/getConceptosFromPayment/${id_payment}`);
  }

  //Dispara un mensaje de whats cuando se aparta un auto
  postSendmesaggeApartados(body) {
    return this.http.post(`${this.BASE_URL}/sendmesagge/whatsapp/apartados?backend=azure`, JSON.stringify(body));
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'ic-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  constructor() {}
}

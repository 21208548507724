<div class="ic-option flex justify-around">
  <div class="ic-option__item flex justify-start" *ngFor="let options of options">
    <button
      class="flex items-center pl-2"
      [class]="options.classBtn"
      [ngClass]="options.index === indexBtn ? options.ngClass : ''"
      (click)="emitChangeData(options.event, options.index)">
      <div class="ic-circle absolute" [class]="options.circleClass"></div>

      <span class="pl-3"> {{ options.label }} </span>
    </button>
  </div>
</div>

import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'ic-graphic',
  templateUrl: './graphic.component.html',
  styleUrls: ['./graphic.component.scss'],
})
export class GraphicComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  constructor() {}

  @Input() eventRefreshGraphic: Observable<void>;

  //Graphic
  @Input() lineChartData: ChartConfiguration['data'];

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {},
    scales: {
      x: {
        display: false,
      },
      'y-axis-0': {
        display: false,
        position: 'left',
      },
    },
    plugins: {
      legend: { display: false },
    },
  };

  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  ngOnInit(): void {
    this.subs.add(this.eventRefreshGraphic.subscribe(() => this.chart?.update()));
  }

  public chartClicked({ event, active }: { event?: ChartEvent; active?: {}[] }): void {
    //console.log(event, active);
  }

  public chartHovered({ event, active }: { event?: ChartEvent; active?: {}[] }): void {
    //console.log(event, active);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

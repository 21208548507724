import { Injectable } from '@angular/core';

import { ProfileType } from '@core/models/profile';

const TOKEN_KEY = 'token-api';
const USER_PROFILE = 'user-profile';
const USER_GROUPS = 'user-groups';
const LIST_ALLCARANTYHOST = 'list-carantyhost';
const RESOURCES = 'resources-crm';
const LIST_SCOPES = 'list-scopes';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor() {}

  logoutRemove(): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(USER_PROFILE);
    window.localStorage.removeItem(USER_GROUPS);
    window.localStorage.removeItem(LIST_ALLCARANTYHOST);
    window.localStorage.removeItem(RESOURCES);
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public savedRol(scopes) {
    window.localStorage.removeItem(LIST_SCOPES);
    window.localStorage.setItem(LIST_SCOPES, JSON.stringify(scopes));
  }

  public getRol() {
    const scopes = window.localStorage.getItem(LIST_SCOPES);
    return JSON.parse(scopes);
  }

  public savedListCarantyHost(listUsers) {
    window.localStorage.removeItem(LIST_ALLCARANTYHOST);
    window.localStorage.setItem(LIST_ALLCARANTYHOST, JSON.stringify(listUsers));
  }

  public getListCarantyHost() {
    const listUsers = window.localStorage.getItem(LIST_ALLCARANTYHOST);
    return JSON.parse(listUsers);
  }

  public savedResources(resources) {
    window.localStorage.removeItem(RESOURCES);
    window.localStorage.setItem(RESOURCES, JSON.stringify(resources));
  }

  public getListResources() {
    const resorces = window.localStorage.getItem(RESOURCES);
    return JSON.parse(resorces);
  }
}

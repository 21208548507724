import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AutosService {
  readonly BASE_URL: string = `${environment.api_url}`;
  readonly KEY_TOKEN: string = `${environment.key_token}`;
  readonly NAMEHEADER_TOKEN: string = `${environment.header_api_key}`;

  constructor(private http: HttpClient) {}

  getCarsByStage(pageSize: number, button: string, stageCar: string, field_value?: string) {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('pageSize', pageSize);
    httpParam = httpParam.append('field', 'date_string');
    httpParam = httpParam.append('button', button);
    httpParam = httpParam.append('stage', stageCar);
    httpParam = httpParam.append('field_value', field_value ? field_value : 'date_string');

    return this.http.get(`${this.BASE_URL}/cars/getCarsByStage`, {
      params: httpParam,
    });
  }

  getCarsToPost() {
    let httpParam = new HttpParams();

    httpParam = httpParam.append('button', 'next');
    httpParam = httpParam.append('field_value', '');

    return this.http.get(`${this.BASE_URL}/cars/getRegisteredCars`, {
      params: httpParam,
    });
  }

  getCarsSold() {
    return this.http.get(`${this.BASE_URL}/payments/getAutosVendidos`);
  }

  getCarsSoldDetail(idCar: string) {
    return this.http.get(`${this.BASE_URL}/payments/getDetalleAutoVendido/${idCar}`);
  }

  getPublicacionesByPage(limit: number = 100000, page: number = 5) {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('limit', limit);
    httpParam = httpParam.append('flag', 'admin');

    return this.http.get(`${this.BASE_URL}/cars/getPublicacionesByPage/${page}`, {
      params: httpParam,
    });
  }

  /*getAutosPublished(button?: string, pageSize?: number, field?: string) {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('button', 'next');
    httpParam = httpParam.append('ispost', 'no');
    httpParam = httpParam.append('field', 'date_string');
    httpParam = httpParam.append('pageSize', '10');
    httpParam = httpParam.append('field_value', field ? field : 'date_string');

    return this.http.get(`${this.BASE_URL}/cars/getCarsByPage`, { params: httpParam });
  }*/

  getDataCarsGraphics(initial_date: string, final_date: string, mostrar_por: string) {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('initial_date', initial_date);
    httpParam = httpParam.append('final_date', final_date);
    httpParam = httpParam.append('mostrar_por', mostrar_por);

    return this.http.get(`${this.BASE_URL}/cars/range/date`, {
      params: httpParam,
    });
  }

  getDataCarsGraphicsV2(initial_date: string, final_date: string, show_by: string) {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('initial_date', initial_date);
    httpParam = httpParam.append('final_date', final_date);
    httpParam = httpParam.append('show_by', show_by);

    return this.http.get(`${this.BASE_URL}/cars/graphics/carsCounter`, {
      params: httpParam,
    });
  }

  getGeneralCarDetail(idCar: string) {
    return this.http.get(`${this.BASE_URL}/cars/car/getgeneralcardetail/detail/${idCar}`);
  }

  getCarDetailForOffersPublishedAndNotPublished(idCar: string, flag_detail: string) {
    let httpParam = new HttpParams().set('flag_detail', flag_detail);

    return this.http.get(`${this.BASE_URL}/cars/car/getCarForOffersPublishedAndNotPublished/detail/${idCar}`, {
      params: httpParam,
    });
  }

  disabledPublish(idCar: string, body) {
    return this.http.put(`${this.BASE_URL}/cars/status/${idCar}`, body);
  }

  changeAutoApartado(...params) {
    return this.http.put(`${this.BASE_URL}/cars/transactions/setApartado`, params[0]);
  }

  getDischargedList(criteria: string = '') {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('pagina_seleccionada', 1);
    httpParam = httpParam.append('elementos_pagina', 50);
    httpParam = httpParam.append('buscar', criteria);

    return this.http.get(`${this.BASE_URL}/cars/disabled?backend=azure`, { params: httpParam });
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoaderService } from '@core/services/loader.service';
import { ErrorBackService } from '@core/services/error-back.service';
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private countRequest = 0;
  constructor(private loaderService: LoaderService, private errorBackService: ErrorBackService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.countRequest) {
      this.loaderService.isLoading.next(true);
    }
    this.countRequest++;

    const KEY_TOKEN: string = `${environment.key_token}`;
    const NAMEHEADER_TOKEN: string = `${environment.header_api_key}`;

    if (request.url.includes('backend=azure')) {
      const backen_azure = environment.backen_azure;
      request = request.clone({
        setHeaders: {
          'x-functions-key': environment.backen_azure.keycode,
          'Content-type': 'application/json',
          [backen_azure.header]: backen_azure.key,
        },
        setParams: {
          [backen_azure.code]: backen_azure.keycode,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json',
          [NAMEHEADER_TOKEN]: KEY_TOKEN,
        },
      });
    }

    //Si la respuesta no es exitosa se reintenta 3 veces
    return next.handle(request).pipe(
      retry(3),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          this.errorBackService.handleError(error);
        }
        return throwError(() => error);
      }),
      finalize(() => {
        this.countRequest--;
        if (!this.countRequest) {
          this.loaderService.isLoading.next(false);
        }
      })
    );
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'ic-label-recent',
  templateUrl: './label-recent.component.html',
  styleUrls: ['./label-recent.component.scss'],
})
export class LabelRecentComponent {
  @Input() label;
  @Input() color;

  constructor() {}
}

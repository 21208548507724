import { Component, Input } from '@angular/core';

@Component({
  selector: 'ic-button-viewall',
  templateUrl: './button-viewall.component.html',
  styleUrls: ['./button-viewall.component.scss'],
})
export class ButtonViewallComponent {
  @Input() toRedirect;
  @Input() label;

  constructor() {}
}

import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { SubSink } from 'subsink';
import { AutosService } from '@core/services/autos.service';
import { OffersService } from '@core/services/offers.service';
import { PaymentService } from '@core/services/payment.service';
import { ConceptosService } from '@core/services/conceptos.service';
import { TableColumn, ActionsTable, ConditionTable } from '@core/models/components.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'ic-tabs-auto',
  templateUrl: './tabs-auto.component.html',
  styleUrls: ['./tabs-auto.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabsAutoComponent implements OnInit, OnDestroy {
  @Input() idCar: string = '';
  @Input() idPayment: string = '';
  @Input() offerId: string = '';
  @Input() flag_detail: string = '';
  public dataDetail: any;

  hrefEnviroment = `${environment.production ? 'https://www.' : 'https://dev.'}caranty.com/compra-un-auto/details/`;

  //Table de conceptos
  displayedColumnsConceptos: TableColumn[];
  lRegConceptos: any[];

  @Output() getDataDetailTabsEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventDeleteAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventEditAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventRefundAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventDownAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventUpAction: EventEmitter<any> = new EventEmitter<any>();

  //Observable para controlar evento de refresh
  @Input() eventRefreshApiConcepto: Observable<void>;
  @Output() getDetailConceptosListEmit: EventEmitter<any> = new EventEmitter<any>();

  private subs = new SubSink();

  conditions: Array<ConditionTable> = [
    { campo1: { campo: 'active' }, operator: `==`, campo2: { value: true } }, //Si
    { campo1: { campo: 'active' }, operator: `==`, campo2: { value: false } }, //Si
    { campo1: { campo: 'reembolso' }, operator: `==`, campo2: { value: 1 } },
    { campo1: { campo: 'reembolso' }, operator: `==`, campo2: { value: 0 } }, //Si
    { campo1: { campo: 'reembolso' }, operator: `!=`, campo2: { value: 3 } }, //Si
    { campo1: { campo: 'reembolso' }, operator: `!=`, campo2: { value: 0 } },
  ];

  actionsTable: Array<ActionsTable> = [
    {
      action: 'edit',
      tooltip: 'Actualizar datos de un concepto.',
      multiIndexCondition: ['1&4'],
    },
    /*{
      action: 'down',
      tooltip: 'Dar de baja un concepto (Desactivarlo ó Borrado lógico).',
      multiIndexCondition: ['1&5'],
    },
    {
      action: 'active',
      tooltip: 'Activar un concepto.',
      multiIndexCondition: ['2&4'],
    },*/
    { action: 'delete', tooltip: 'Eliminar de forma permanente este concepto.', multiIndexCondition: ['5&1'] },
  ];

  actionsTableReembolso: Array<ActionsTable> = [
    { action: 'refund', tooltip: 'Realizar un reembolso.', indexCondition: 6 },
  ];

  constructor(
    private autosService: AutosService,
    private offersService: OffersService,
    private paymentService: PaymentService,
    private conceptosService: ConceptosService
  ) {
    this.displayedColumnsConceptos = [
      { dataKey: 'amount', name: 'Monto', isSortable: true, pipe: 'money' },
      { dataKey: 'tipo_pago', name: 'Tipo de pago', isSortable: true },
      { dataKey: 'fecha_pago', name: 'Fecha de Pago', isSortable: true },
      {
        dataKey: 'Acciones',
        name: 'Acciones',
        actions: this.actionsTable,
        typeElement: 'actions',
      },
      {
        dataKey: 'AccionesReembolso',
        name: 'Reembolso',
        actions: this.actionsTableReembolso,
        typeElement: 'actions',
      },
    ];
  }

  ngOnInit(): void {
    switch (this.flag_detail) {
      case 'vendidos':
        this.subs.add(
          this.autosService.getCarsSoldDetail(this.idCar).subscribe((response: any) => {
            if (response.code === 200) {
              this.dataDetail = response.result;
              this.getDataDetailTabsEmit.emit(response.result);
            }
          })
        );
        break;
      case 'offers':
        this.subs.add(
          this.offersService.getOfferDetail(this.offerId).subscribe((response: any) => {
            if (response.code === 200) {
              this.dataDetail = {
                car: response.result.car,
                user: response.result.seller,
              };
              this.getDataDetailTabsEmit.emit(response.result);
            }
          })
        );
        break;
      case 'payment':
        this.subs.add(
          this.paymentService.getDetailPayment(this.idPayment).subscribe((response: any) => {
            if (response.code === 200) {
              this.dataDetail = {
                car: response.result.car,
                user: response.result.seller,
              };
              this.getDataDetailTabsEmit.emit(response.result);
            }
          })
        );
        break;
      case 'concepto':
        this.subs.add(
          this.paymentService.getDetailPayment(this.idPayment).subscribe((response: any) => {
            if (response.code === 200) {
              this.dataDetail = {
                car: response.result.car,
                user: response.result.seller,
              };
              this.getDataDetailTabsEmit.emit(response.result);
              this.getDetailConceptosList(this.idPayment);
            }
          }),
          this.eventRefreshApiConcepto.subscribe(() => this.getDetailConceptosList(this.idPayment))
        );
        break;
      default:
        this.subs.add(
          this.autosService
            .getCarDetailForOffersPublishedAndNotPublished(this.idCar, this.flag_detail)
            .subscribe((response: any) => {
              if (response.code === 200) {
                this.dataDetail = response.result;
                this.getDataDetailTabsEmit.emit(response.result);
              }
            })
        );
        break;
    }
  }

  eventEditDeleteGet($event) {
    this.eventDeleteAction.emit($event);
  }

  eventDownGet($event) {
    this.eventDownAction.emit($event);
  }

  eventActiveGet($event) {
    this.eventUpAction.emit($event);
  }

  eventEditActionGet($event) {
    this.eventEditAction.emit($event);
  }

  eventRefundActionGet($event) {
    this.eventRefundAction.emit($event);
  }

  getDetailConceptosList(idPayment) {
    this.subs.add(
      this.conceptosService.getConceptosFromPayment(idPayment).subscribe((response: any) => {
        if (response.code === 200) {
          this.lRegConceptos = response.result ?? [];
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

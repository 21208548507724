<div class="mx-auto my-4 flex w-full items-center justify-between rounded-lg bg-ica-componettotales p-2">
  <div class="flex items-center justify-start">
    <button *ngIf="index_dateTrimestre > 0" class="ic-btn-date" (click)="eventTrimestrePrevious()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <span class="ic-label-primary-xl">{{ data_dateTrimestre?.label }}</span>
    <button *ngIf="index_dateTrimestre < 3" class="ic-btn-date" (click)="eventTrimestreNext()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>

  <div class="pr-3">
    <span class="ic-label-primary-xl"> {{ labelTotal }}: {{ total }} </span>
  </div>
</div>

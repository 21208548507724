import { ProfileType } from '@core/models/profile';
import { GraphService } from '@core/services/graph.service';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

import { filter, delay } from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SubSink } from 'subsink';
import { avatarName } from '@shared/utils/utils';

@UntilDestroy()
@Component({
  selector: 'ic-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  private subs = new SubSink();
  refreshLater: boolean = false;

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  loginDisplay = false;
  installEvent: any = null;
  isOpenBody = false;
  profile: ProfileType | null = null;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private observer: BreakpointObserver,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _graphService: GraphService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.matIconRegistry.addSvgIcon(
      'notifications',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Notification.svg')
    );
  }

  ngOnInit(): void {
    this.subs.add(
      this.msalBroadcastService.msalSubject$
        .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.authService.instance.setActiveAccount(payload.account);
        }),

      this.msalBroadcastService.inProgress$
        .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
        .subscribe(() => {
          this.setLoginDisplay();
        }),

      this._graphService.profile$.subscribe((profile: ProfileType) => {
        this.profile = profile;
        this.profile.letterAvatar = avatarName(profile.displayName || 'N A');
      })
    );

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      this.installEvent = e;
      // Save the event because you'll need to trigger it later.
    });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;

    if (this.loginDisplay) {
      this._graphService.getDataMeProfile().subscribe((res) => {});
    }
  }

  ngAfterViewInit() {
    this.subs.add(
      this.observer
        .observe(['(max-width: 768px)'])
        .pipe(delay(1), untilDestroyed(this))
        .subscribe((res: any) => {
          if (res.matches) {
            this.sidenav.mode = 'over';
            this.sidenav.close();
          } else {
            this.sidenav.mode = 'side';
            this.sidenav.open();
          }
        }),

      this.router.events
        .pipe(
          untilDestroyed(this),
          filter((e) => e instanceof NavigationEnd)
        )
        .subscribe(() => {
          if (this.sidenav.mode === 'over') {
            this.sidenav.close();
          }
        })
    );

    this.changeDetector.detectChanges();
  }

  @HostListener('window: beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: Event) {
    console.log(event);
    event.preventDefault();
    this.installEvent = event;
  }

  installByUserPWA() {
    if (this.installEvent) {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((rta: any) => {
        if (rta.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
      });
    }
  }

  upgradeApp() {
    localStorage.clear();
    window.location.reload();
  }

  logout(popup?: boolean) {
    sessionStorage.clear();
    localStorage.clear();
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

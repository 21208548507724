import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'ic-mt-select-date',
  templateUrl: './mt-select-date.component.html',
  styleUrls: ['./mt-select-date.component.scss'],
})
export class MatToolbarComponent implements OnInit {
  @Input() total;
  @Input() labelTotal;
  @Output() eventDate: EventEmitter<any> = new EventEmitter<any>();
  private anio = new Date().getFullYear();

  //Operacion con fecha para obtener el trimestre actual.
  public index_dateTrimestre = Math.floor((new Date().getMonth() + 3) / 3) - 1;

  public data_dateTrimestre: any;
  public dataDate = [
    {
      label: 'Enero - Marzo',
      labels: { 1: 'Enero', 2: 'Febrero', 3: 'Marzo' },
      dateInit: `${this.anio}-01-01`,
      dateFin: `${this.anio}-03-31`,
    },
    {
      label: 'Abril - Junio',
      labels: { 4: 'Abril', 5: 'Mayo', 6: 'Junio' },
      dateInit: `${this.anio}-04-01`,
      dateFin: `${this.anio}-06-30`,
    },
    {
      label: 'Julio - Septiembre',
      labels: { 7: 'Julio', 8: 'Agosto', 9: 'Septiembre' },
      dateInit: `${this.anio}-07-01`,
      dateFin: `${this.anio}-09-30`,
    },
    {
      label: 'Octubre - Diciembre',
      labels: { 10: 'Octubre', 11: 'Noviembre', 12: 'Diciembre' },
      dateInit: `${this.anio}-10-01`,
      dateFin: `${this.anio}-12-31`,
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.getDateTrimestre(this.index_dateTrimestre);
  }

  getDateTrimestre(index) {
    this.data_dateTrimestre = this.dataDate[index];
    this.eventDate.emit(this.data_dateTrimestre);
  }

  eventTrimestreNext() {
    if (this.index_dateTrimestre <= 3) {
      this.getDateTrimestre(++this.index_dateTrimestre);
    }
  }

  eventTrimestrePrevious() {
    if (this.index_dateTrimestre >= 0) {
      this.getDateTrimestre(--this.index_dateTrimestre);
    }
  }
}

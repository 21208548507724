import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ic-label-payment-slope',
  templateUrl: './label-payment-slope.component.html',
  styleUrls: ['./label-payment-slope.component.scss'],
})
export class LabelPaymentSlopeComponent {
  @Input() label;
}

import { Component, Input } from '@angular/core';
@Component({
  selector: 'ic-head-detail',
  templateUrl: './head-detail.component.html',
  styleUrls: ['./head-detail.component.scss'],
})
export class HeadDetailComponent {
  @Input() label;
  @Input() routerLink;

  constructor() {}
}

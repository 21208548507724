export const environment = {
  production: false,
  api_url: 'https://carantyamdev.azure-api.net/caranty-dev',
  key_token: '11c1c0d6d3b24f4888d23c9a01e9454d',
  header_api_key: 'api-key',
  backen_azure: {
    header: 'api-key',
    key: '11c1c0d6d3b24f4888d23c9a01e9454d',
    code: 'code',
    keycode: 'IeITEeTN7UQzHPbjTGoO139sWRnEKZCb5N7F36p4guUiwbvMt0mmaA==',
  },
  msalAuth: {
    graphEndpoint: 'https://graph.microsoft.com/v1.0/me',
    clientId: 'b9b72191-d45e-41f8-b790-459796d68fa6',
    authority: 'https://login.microsoftonline.com/e0ee65fd-9f80-4118-99ca-6784aa65d6e1',
  },
  appInsights: {
    instrumentationKey: 'f82346a1-23fa-47de-b16a-077be1531a47',
  },
};

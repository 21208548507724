<mat-tab-group mat-align-tabs="start">
  <mat-tab [label]="'TABS_DETAIL.TAB1.title' | translate">
    <div class="grid w-full grid-cols-2 gap-2 py-4" *ngIf="dataDetail?.car">
      <div class="flex items-center" *ngIf="dataDetail.car.id">
        <span class="ic-label-primary">Id: </span>
        <a class="ic-links" target="_blank" [href]="hrefEnviroment + dataDetail.car?.id">
          {{ dataDetail.car?.id }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-3 w-3">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
          </svg>
        </a>
      </div>

      <div>
        <span class="ic-label-primary">Modelo: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.model }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Tipo de auto: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.type }}</span>
      </div>

      <div>
        <span class="ic-label-primary">Marca: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.brand }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Caballos de fuerza: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.horses }}</span>
      </div>

      <div>
        <span class="ic-label-primary">Año: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.year }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Condición de llantas: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.tires }}</span>
      </div>

      <div>
        <span class="ic-label-primary">Versión: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.version }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Color: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.color }}</span>
      </div>

      <div>
        <span class="ic-label-primary">KM: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.km }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Número de dueños: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.owner }}</span>
      </div>

      <div>
        <span class="ic-label-primary">Número Placas: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.placas }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Duplicado de llave: </span>
        <span class="ic-label-seconday">
          {{ dataDetail.car?.keys === true ? 'SI' : 'NO' }}
        </span>
      </div>

      <div>
        <span class="ic-label-primary">VIN: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.vin_number }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Transmisión: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.transmission }}</span>
      </div>

      <div>
        <span class="ic-label-primary">Tipo de frenos: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.brakes }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Ubicación: </span>
        <span class="ic-label-seconday">{{ dataDetail.car?.ubicacion }}</span>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'TABS_DETAIL.TAB2.title' | translate" *ngIf="dataDetail?.documents">
    <div class="grid w-full grid-cols-2 gap-2 py-4">
      <div class="flex">
        <span class="ic-label-primary">Análisis de información: </span>
        <a class="ic-links">Descargar aquí</a>
      </div>
      <div class="flex">
        <span class="ic-label-primary">Expediente:</span>
        <a class="ic-links">Descargar aquí</a>
      </div>

      <!--div class="flex">
        <span class="ic-label-primary">Factura o refactura:</span> Pendiente
      </!--div>
      <div--- class="flex">
        <span class="ic-label-primary">Verificación: </span> Pendiente
      </div--->

      <div class="flex">
        <span class="ic-label-primary">Tarjeta de circulación:</span>
        <a class="ic-links"> Descargar aquí</a>
      </div>
      <!--div class="flex">
        <span class="ic-label-primary">Identificación oficial: </span>
        Pendiente
      </div!-->

      <div class="flex">
        <span class="ic-label-primary">Comprobante de domicilio:</span>
        <a class="ic-links"> Descargar aquí</a>
      </div>
      <div class="flex">
        <span class="ic-label-primary">Tenencia:</span>
        <a class="ic-links"> Descargar aquí</a>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'TABS_DETAIL.TAB3.title' | translate">
    <div class="grid w-full grid-cols-2 gap-2 py-4" *ngIf="dataDetail?.user">
      <div>
        <span class="ic-label-primary">Nombre completo: </span>
        <span class="ic-label-seconday">{{ dataDetail.user?.fullname }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Identificación INE: </span>
        <span class="ic-label-seconday">{{ dataDetail.user?.identificacionIne }}</span>
      </div>

      <div>
        <span class="ic-label-primary">Fecha de nacimiento: </span>
      </div>
      <div>
        <span class="ic-label-primary">RFC: </span>
        <span class="ic-label-seconday">{{ dataDetail.user?.rfc }}</span>
      </div>

      <div>
        <span class="ic-label-primary">CURP: </span>
        <span class="ic-label-seconday">{{ dataDetail.user?.curp }}</span>
      </div>

      <div>
        <span class="ic-label-primary">Cuenta bancaria o CLABE: </span>
        <span class="ic-label-seconday">{{ dataDetail.user?.clabe }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Institución financiera o banco: </span>
        <span class="ic-label-seconday">{{ dataDetail.user?.banco }}</span>
      </div>

      <div>
        <span class="ic-label-primary">Domicilio: </span>
        <span class="ic-label-seconday">{{ dataDetail.user?.domicilio }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Colonia: </span>
        <span class="ic-label-seconday">{{ dataDetail.user?.colonia }}</span>
      </div>

      <div>
        <span class="ic-label-primary">Municipio: </span>
        <span class="ic-label-seconday">{{ dataDetail.user?.municipio }}</span>
      </div>
      <div>
        <span class="ic-label-primary">Estado: </span>
        <span class="ic-label-seconday">{{ dataDetail.user?.estado }}</span>
      </div>
    </div>
  </mat-tab>

  <mat-tab [label]="'TABS_DETAIL.TAB4.title' | translate" *ngIf="flag_detail === 'concepto'">
    <ic-table-data
      [tableData]="lRegConceptos"
      [tableColumns]="displayedColumnsConceptos"
      color_td="active-color__published"
      [isPageable]="true"
      [defaultPageSize]="10"
      [conditions]="conditions"
      (eventDeleteAction)="eventEditDeleteGet($event)"
      (eventDownAction)="eventDownGet($event)"
      (eventUpAction)="eventActiveGet($event)"
      (eventEditAction)="eventEditActionGet($event)"
      (eventRefundAction)="eventRefundActionGet($event)">
    </ic-table-data>
  </mat-tab>
</mat-tab-group>

<div class="sidenav flex h-screen flex-col">
  <div class="flex justify-center pb-4 pt-6 text-center">
    <img
      src="../../../../assets/img/i-c-logo-blanco.png"
      alt="Logo de I-Caranty"
      class="cursor-pointer"
      routerLink="/" />
  </div>

  <p class="my-6 cursor-pointer text-center text-3xl tracking-[0.7em] text-white" routerLink="/">ADMIN</p>

  <div class="flex w-full flex-col">
    <div class="mb-4 ml-3 flex flex-col">
      <span class="font-semibold text-ica-textsidebar">{{ dataProfile?.givenName }}</span>
      <span class="font-semibold text-ica-textsidebar">{{ dataProfile?.surname }}</span>
      <span class="font-semibold text-ica-primary">{{ dataProfile?.userPrincipalName }}</span>
    </div>

    <ul class="px-1">
      <li class="m-0 p-0" *ngFor="let menu of menu" [ngClass]="{ 'pl-2': menu.type !== 'modulo' }">
        <mat-divider class="mx-0 my-1" *ngIf="menu.type === 'modulo'"></mat-divider>

        <ng-container *ngIf="menu.inProduction && environment" [ngTemplateOutlet]="item"> </ng-container>

        <ng-container *ngIf="!environment" [ngTemplateOutlet]="item"> </ng-container>

        <ng-template #item>
          <a
            *ngIf="menu.routerLink"
            mat-button
            class="m-0 p-0"
            [routerLink]="menu.routerLink"
            routerLinkActive="active">
            <!--[routerLinkActiveOptions]="{exact: true}" -> se desactiva la ruta exacta para el activado del menu--->
            <span class="m-0 p-0" [ngClass]="menu.type === 'modulo' ? 'li-menu-module' : 'li-menu'">
              {{ menu.label }}
            </span>
          </a>
        </ng-template>
      </li>
    </ul>
  </div>
</div>

<div class="flex-item">
  <div style="display: block">
    <canvas
      baseChart
      width="400px"
      height="100px"
      [data]="lineChartData"
      [options]="lineChartOptions"
      lineChartPlugins
      [type]="lineChartType"
      (chartHover)="chartHovered($event)"
      (chartClick)="chartClicked($event)">
    </canvas>
  </div>
</div>

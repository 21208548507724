import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AppInsightsService } from '@core/services/app-insights.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private appInsightsService: AppInsightsService) {
    super();
  }

  override handleError(error: Error) {
    console.log('Error: ', error);
    // Manually log exception => AppInsights Azure
    this.appInsightsService.logException(error);
  }
}

import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  viewImage(imageUrl: string) {
    return Swal.fire({
      imageUrl,
      imageHeight: 1500,
      imageAlt: 'A tall image',
    });
  }

  confirmAction(message: string) {
    return Swal.fire({
      title: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#70BE01',
      cancelButtonColor: '#5E5E5E',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    });
  }

  success(message: string) {
    return Swal.fire({
      text: message,
      icon: 'success',
      allowOutsideClick: false,
      confirmButtonColor: '#70BE01',
    });
  }

  error(message: string) {
    return Swal.fire({
      text: message,
      icon: 'error',
      allowOutsideClick: false,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#70BE01',
    });
  }

  warning(message: string, title = '') {
    return Swal.fire({
      title: title,
      text: message,
      icon: 'warning',
      allowOutsideClick: false,
      confirmButtonColor: '#70BE01',
    });
  }

  info(message: string) {
    return Swal.fire({
      text: message,
      icon: 'info',
      allowOutsideClick: false,
      confirmButtonColor: '#70BE01',
    });
  }
}

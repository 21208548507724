import { AfterViewInit, Component, OnDestroy, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TableColumn, ConditionTable } from '@core/models/components.model';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Observable } from 'rxjs';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { SubSink } from 'subsink';

@Component({
  selector: 'ic-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.scss'],
})
export class TableDataComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();

  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[];
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;

  @Input() placeholder: string;
  @Input() color_td = 'test_color';
  @Input() notRecord = 'No existen registros...';
  @Input() searchManual = false;
  @Input() isPageable = false;
  @Input() isSortable = false;
  @Input() isFilterable = false;
  @Input() tableColumns: TableColumn[];
  @Input() rowActionIcon: string;
  @Input() paginationSizes: number[] = [10, 15, 20, 25, 50, 100];
  @Input() defaultPageSize = this.paginationSizes[1];
  @Input() conditions: ConditionTable[];

  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToDetails: EventEmitter<any> = new EventEmitter<any>();

  /****Eventos exclusivos para los actions *****/
  @Output() eventDeleteAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventEditAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventRefundAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventDownAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventUpAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventPrintAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventCustomAction: EventEmitter<any> = new EventEmitter<any>();

  //Serach
  @Output() eventInputSerach: EventEmitter<any> = new EventEmitter<any>();

  @Input() $eventRefreshData: Observable<any>;

  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  searchField = new FormControl('');

  constructor(private _liveAnnouncer: LiveAnnouncer, private _form: UntypedFormBuilder) {}

  ngOnInit(): void {
    const columnNames = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.dataKey);
    if (this.rowActionIcon) {
      this.displayedColumns = [this.rowActionIcon, ...columnNames];
    } else {
      this.displayedColumns = columnNames;
    }

    if (this.$eventRefreshData) {
      this.subs.add(
        this.$eventRefreshData.subscribe((data) => {
          this.setTableDataSource(data);
        })
      );
    }
  }

  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.matPaginator;
  }

  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
  }

  applyFilter(event: Event) {
    if (!this.searchManual) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.tableDataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  emitRowAction(row: any) {
    this.rowAction.emit(row);
  }

  emitGoToDetails(data: any) {
    this.goToDetails.emit(data);
  }

  /****Eventos exclusivos para los actions *****/
  emitEventDelete(data) {
    this.eventDeleteAction.emit(data);
  }

  emitEventEdit(data) {
    this.eventEditAction.emit(data);
  }

  emitEventRefund(data) {
    this.eventRefundAction.emit(data);
  }

  emitEventDown(data) {
    this.eventDownAction.emit(data);
  }

  emitEventActive(data) {
    this.eventUpAction.emit(data);
  }

  emitEventPrint(data) {
    this.eventPrintAction.emit(data);
  }

  emitEventCustom($event) {
    this.eventCustomAction.emit($event);
  }

  // this method is used to sort the table data
  announceSortChange(sortParameters: Sort) {
    if (sortParameters.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortParameters.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  conditionRow(element, index?: number | Array<string>): boolean {
    if (typeof index === 'number' && index > 0) {
      index = index - 1;
      return this.evaluaCompareOperator(index, element);
    }

    if (typeof index === 'object') {
      let resultCondition: boolean;
      index.forEach((item) => {
        const result = item.split(/[\|\&]/);
        if (item.includes('|')) {
          resultCondition =
            this.evaluaCompareOperator(parseInt(result[0]) - 1, element) ||
            this.evaluaCompareOperator(parseInt(result[1]) - 1, element);
        } else if (item.includes('&')) {
          resultCondition =
            this.evaluaCompareOperator(parseInt(result[0]) - 1, element) &&
            this.evaluaCompareOperator(parseInt(result[1]) - 1, element);
        }
      });
      return resultCondition;
    }
    return false;
  }

  evaluaCompareOperator(index: number, element) {
    switch (this.conditions[index].operator) {
      case `==`:
        return (
          (this.conditions[index].campo1.campo
            ? element[this.conditions[index].campo1.campo]
            : this.conditions[index].campo1.value) ==
          (this.conditions[index].campo2.campo
            ? element[this.conditions[index].campo2.campo]
            : this.conditions[index].campo2.value)
        );
      case `>`:
        return (
          (this.conditions[index].campo1.campo
            ? element[this.conditions[index].campo1.campo]
            : this.conditions[index].campo1.value) >
          (this.conditions[index].campo2.campo
            ? element[this.conditions[index].campo2.campo]
            : this.conditions[index].campo2.value)
        );
      case `<`:
        return (
          (this.conditions[index].campo1.campo
            ? element[this.conditions[index].campo1.campo]
            : this.conditions[index].campo1.value) <
          (this.conditions[index].campo2.campo
            ? element[this.conditions[index].campo2.campo]
            : this.conditions[index].campo2.value)
        );
      case `===`:
        return (
          (this.conditions[index].campo1.campo
            ? element[this.conditions[index].campo1.campo]
            : this.conditions[index].campo1.value) ===
          (this.conditions[index].campo2.campo
            ? element[this.conditions[index].campo2.campo]
            : this.conditions[index].campo2.value)
        );
      case `<=`:
        return (
          (this.conditions[index].campo1.campo
            ? element[this.conditions[index].campo1.campo]
            : this.conditions[index].campo1.value) <=
          (this.conditions[index].campo2.campo
            ? element[this.conditions[index].campo2.campo]
            : this.conditions[index].campo2.value)
        );
      case `>=`:
        return (
          (this.conditions[index].campo1.campo
            ? element[this.conditions[index].campo1.campo]
            : this.conditions[index].campo1.value) >=
          (this.conditions[index].campo2.campo
            ? element[this.conditions[index].campo2.campo]
            : this.conditions[index].campo2.value)
        );
      case `!=`:
        return (
          (this.conditions[index].campo1.campo
            ? element[this.conditions[index].campo1.campo]
            : this.conditions[index].campo1.value) !=
          (this.conditions[index].campo2.campo
            ? element[this.conditions[index].campo2.campo]
            : this.conditions[index].campo2.value)
        );
      default:
        return false;
    }
  }

  onSubmitSearch() {
    if (this.searchField.valid) {
      let search = this.searchField.value;
      this.eventInputSerach.emit(search);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
